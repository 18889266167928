import tokenLogos from "./tokenLogos";

export const tokensUnderlying = {
    "WAVES": {
        icon: tokenLogos.WAVES,
        name: "WAVES",
    },
    "CPhNn4f754kvEvt7UFbC5NScGT7BNWuZivuEwPF2BpPY": {
        icon: tokenLogos.WBTC,
        name: "WBTCu",
    },
    "AEvGpbS2BkKMtvpQAe2fdKVKg3yampiMvrNVLPXy25ML": {
        icon: tokenLogos.WETHu,
        name: "WETHu"
    },
    "GjwAHMjqWzYR4LgoNy91CxUKAGJN79h2hseZoae4nU8t": {
        icon: tokenLogos.UNIT0,
        name: "UNIT0",
    },
    "Atqv59EYzjFGuitKVnMRk6H8FukjoV3ktPorbEys25on": {
        icon: tokenLogos.WX,
        name: "WX Network",
    },
    "HEB8Qaw9xrWpWs8tHsiATYGBWDBtP2S7kcPALrMu43AS": {
        icon: tokenLogos.PUZZLE_NEW,
        name: "Puzzle",
    },
    "Ehie5xYpeN8op1Cctc6aGUrqx8jq3jtf1DSjXDbfm7aT": {
        icon: tokenLogos.SWOP_WAVESXTN,
        name: "SWOP",
    },
    "2thsACuHmzDMuNezPM32wg9a3BwUzBWDeSKakgz3cw21": {
        icon: tokenLogos.POWER,
        name: "POWER",
    },
    "DG2xFkPdDwKUoBkzGAhQtLpSGzfXLiCYPEzeKH2Ad24p": {
        icon: tokenLogos.XTN,
        name: "XTN",
    },
    "3VuV5WTmDz47Dmdn3QpcYjzbSdipjQE4JMdNe1xZpX13": {
        icon: tokenLogos.WETH,
        name: "ETH-Ethereum-PPT"
    },
    "Ajso6nTTjptu2UHLx6hfSXVtHFtRBJCkKYd5SAyj7zf5": {
        icon: tokenLogos.PLUTO,
        name: "PLUTO",
    },
    "2Fge5HEBRD3XTeg7Xg3FW5yiB9HVJFQtMXiWMQo72Up6": {
        icon: tokenLogos.WBTC_ERC20_PPT,
        name: "WBTC-ERC20-PPT",
    },
    "7scqyYoVsNrpWbTAc78eRqNVcYLxMPzZs8EQfX7ruJAg": {
        icon: tokenLogos.L2MP,
        name: "L2MP"
    },
    "9wc3LXNA4TEBsXyKtoLE9mrbDD7WMHXvXrCjZvabLAsi": {
        icon: tokenLogos.USDT,
        name: "USDT-ERC20-PPT"
    },
    "HGgabTqUS8WtVFUJzfmrTDMgEccJuZLBPhFgQFxvnsoW": {
        icon: tokenLogos.USDC,
        name: "USDC-ERC20-PPT"
    }
}