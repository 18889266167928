import RootStore from "@stores/RootStore";
import PoolStateFetchService, {
  TPoolToken
} from "@src/services/PoolStateFetchService";
import BN from "@src/utils/BN";
import { ASSETS_TYPE, IPool, OPERATION_STATUS, TOKENS_BY_SYMBOL, TOKENS_LIST } from "@src/constants";
import { getStateByKey } from "@src/utils/getStateByKey";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import nodeService from "@src/services/nodeService";
import { POOLS } from "@src/constants";

export type TPoolStats = {
  totalSupply: BN;
  totalBorrow: BN;
  supplyAPY: BN;
  supplyAPR: BN;
  borrowAPY: BN;
  borrowAPR: BN;
  selfSupply: BN;
  selfBorrow: BN;
  dailyIncome: BN;
  dailyLoan: BN;
  supplyLimit: BN;
  paused: BN;
  isAutostakeAvl?: boolean;
  prices: { min: BN; max: BN };
} & TPoolToken;

const calcApy = (i: BN) => {
  if (!i || i.isNaN()) return BN.ZERO;

  return i.plus(1).pow(365).minus(1).times(100).toDecimalPlaces(2);
};

const calcApr = (i: BN) => {
  if (!i || i.isNaN()) return BN.ZERO;

  return i.times(365).times(100).toDecimalPlaces(2);
};

const updateRate = 1e2;

const calcAutostakeApy = (
  totalSupply: BN,
  interest: BN,
  ASpreLastEarned: BN,
  ASlastEarned: BN,
  ASpreLastBlock: BN,
  ASlastBlock: BN
) => {
  if (!interest || interest.isNaN()) return BN.ZERO;
  const lastBlockStakingRewards = ASlastEarned.minus(ASpreLastEarned).div(
    ASlastBlock.minus(ASpreLastBlock)
  );
  const fStaked = lastBlockStakingRewards
    .div(totalSupply)
    .times(60)
    .times(24)
    .times(0.8);
  return fStaked.plus(interest).plus(1).pow(365).minus(1).times(100);
};

class LendStore {
  public readonly rootStore: RootStore;
  private _fetchServices: { [key: string]: PoolStateFetchService } = {};
  private _poolsTokens: { [key: string]: Array<TPoolToken> } = {};
  private _poolsStats: { [key: string]: Array<TPoolStats> } = POOLS.reduce(
    (acc: { [key: string]: Array<TPoolStats> }, pool) => {
      acc[pool.address] = [];
      return acc;
    },
    {}
  );

  private _poolAddress: string = "";
  private _userCollaterals: { [key: string]: BN } = {};

  public initialized: boolean = false;
  public initializedNumber: number = 0;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);

    POOLS.forEach((pool) => {
      this.setFetchService(pool.address).then(() => {
        this.initializedNumber += 1;
        if (this.initializedNumber === POOLS.length) {
          this.initialized = true;
        }
      });
    });
    reaction(() => this.rootStore.accountStore.address, () => {
      POOLS.forEach((pool) => {
        this.syncPoolsStats(pool.address);
      });
    });
  }

  operationType: OPERATION_STATUS = OPERATION_STATUS.BUY;
  setOperationType = (status: OPERATION_STATUS) => (this.operationType = status);


  private setFetchService = async (poolAddress: string) => {
    if (!poolAddress) return;
    this._fetchServices[poolAddress] = new PoolStateFetchService(poolAddress);
    return await this._fetchServices[
      poolAddress
    ].fetchSetups()
      .then((value: TPoolToken[]) => this.setTokensSetups(poolAddress, value))
      .then(() => this.syncPoolsStats(poolAddress))
      .catch((e) => {
        console.error(e);
        this.rootStore.notificationStore.notify(e.message, { type: "error" })
      });
  };

  private setTokensSetups = (poolAddress: string, v: Array<TPoolToken>) => {
    this._poolsTokens[poolAddress] = v;
  };

  public syncPoolsStats = async (poolAddress: string = this.poolAddress) => {
    const address = this.rootStore.accountStore.address;

    const poolTokens = this._poolsTokens[poolAddress];
    if (!poolTokens) return;

    const keys = poolTokens.reduce(
      (acc, { assetId }) => [
        ...acc,
        `setup_maxSupply_${assetId}`,
        `setup_paused_${assetId}`,
        `total_supplied_${assetId}`,
        `total_borrowed_${assetId}`,
        `autostake_preLastEarned_${assetId}`,
        `autostake_lastEarned_${assetId}`,
        `autostake_preLastBlock_${assetId}`,
        `autostake_lastBlock_${assetId}`,
        ...(address
          ? [`${address}_supplied_${assetId}`, `${address}_borrowed_${assetId}`]
          : [])
      ],
      [] as string[]
    );

    const [state, rates, prices, interests, userCollateral] = await Promise.all(
      [
        nodeService.nodeKeysRequest(poolAddress, keys),
        this._fetchServices[poolAddress].calculateTokenRates(),
        this._fetchServices[poolAddress].getPrices(),
        this._fetchServices[poolAddress].calculateTokensInterest(),
        this._fetchServices[poolAddress].getUserCollateral(address || "")
      ]
    );

    if (!rates) return;

    const stats = this._poolsTokens[poolAddress].map((token, index) => {
      const sup = getStateByKey(state, `total_supplied_${token.assetId}`);
      const totalSupply = new BN(sup ?? "0").times(rates[index].supplyRate);

      const sSup = getStateByKey(state, `${address}_supplied_${token.assetId}`);
      const selfSupply = new BN(sSup ?? "0").times(rates[index].supplyRate);

      const bor = getStateByKey(state, `total_borrowed_${token.assetId}`);
      const totalBorrow = new BN(bor ?? "0").times(rates[index].borrowRate);

      const sBor = getStateByKey(state, `${address}_borrowed_${token.assetId}`);
      const selfBorrow = new BN(sBor ?? "0").times(rates[index].borrowRate);

      const UR = totalBorrow.div(totalSupply);
      const supplyInterest = interests[index].times(UR).times(0.8);

      const p = prices ? prices[index] : { min: BN.ZERO, max: BN.ZERO };
      const dailyLoan = selfBorrow.times(interests[index]);

      const limit = getStateByKey(state, `setup_maxSupply_${token.assetId}`);
      const assetMaxSupply = BN.formatUnits(
        limit ?? "0",
        TOKENS_BY_SYMBOL.XTN.decimals
      );

      // const paused = getStateByKey(state, `setup_paused_${token.assetId}`);
      // const assetPaused = BN.formatUnits(
      //     paused ?? "0",
      //     TOKENS_BY_SYMBOL.XTN.decimals
      // );

      const ASpreLastEarnedNum = getStateByKey(
        state,
        `autostake_preLastEarned_${token.assetId}`
      );
      const ASpreLastEarned = new BN(ASpreLastEarnedNum ?? 0);
      const ASlastEarnedNum = getStateByKey(
        state,
        `autostake_lastEarned_${token.assetId}`
      );
      const ASlastEarned = new BN(ASlastEarnedNum ?? 0);
      const ASpreLastBlockNum = getStateByKey(
        state,
        `autostake_preLastBlock_${token.assetId}`
      );
      const ASpreLastBlock = new BN(ASpreLastBlockNum ?? 0);
      const ASlastBlockNum = getStateByKey(
        state,
        `autostake_lastBlock_${token.assetId}`
      );
      const ASlastBlock = new BN(ASlastBlockNum ?? 0);

      const autostakeAPY = calcAutostakeApy(
          totalSupply,
          supplyInterest,
          ASpreLastEarned,
          ASlastEarned,
          ASpreLastBlock,
          ASlastBlock
      )
      const dailyIncome = ASlastBlockNum ? selfSupply.times(autostakeAPY).div(365).div(100) : selfSupply.times(supplyInterest);
      const underlying = TOKENS_LIST.find((el) => el.assetId === token.assetId)?.underlying ?? []
      return {
        ...token,
        interest: interests[index],
        prices: p,
        supplyLimit: assetMaxSupply,
        paused: new BN(0),
        dailyIncome: dailyIncome.toDecimalPlaces(0),
        dailyLoan: dailyLoan.toDecimalPlaces(0),
        totalSupply: totalSupply.toDecimalPlaces(0),
        selfSupply: selfSupply.toDecimalPlaces(0),
        totalBorrow: totalBorrow.toDecimalPlaces(0),
        selfBorrow: selfBorrow.toDecimalPlaces(0),
        supplyAPY: ASlastBlockNum
          ? autostakeAPY
          : calcApy(supplyInterest),
        isAutostakeAvl: !!ASlastBlockNum,
        supplyAPR: calcApr(supplyInterest),
        borrowAPY: calcApy(interests[index]),
        borrowAPR: calcApr(interests[index]),
        underlying
      };
    });

    
    runInAction(() => {
      this._poolsStats = ({...this._poolsStats, [poolAddress]: stats});
      this._userCollaterals[poolAddress] = new BN(userCollateral);
    });
  };

  public getStatsForPool = (poolAddress: string) => {
    return this._poolsStats[poolAddress]?.filter((stat) => stat.paused.isZero()) || undefined;
  };

  public get poolsStats() {
    return this.getStatsForPool(this.poolAddress);
  }

  public get mergedPoolsStats() {
    return POOLS.reduce((acc, pool) => {
      const stats = this.getStatsForPool(pool.address);
      return [...acc, ...stats];
    }, [] as TPoolStats[]);
  }

  public getStatByAssetId = (assetId: string) => {
    return this.getStatsForPool(this.poolAddress).find(
      (stat) => stat.assetId === assetId
    );
  }

  public getTokensSetups = (poolAddress: string) => {
    return this._poolsTokens[poolAddress] || [];
  };

  public get poolAddress(): string {
    return this._poolAddress;
  }

  public get poolId(): string {
    return this.poolAddress;
  }

  public get poolName(): string {
    return this.pool.name;
  }

  public get userCollateral() {
    return this._userCollaterals[this.poolAddress] || BN.ZERO;
  }

  public pool!: IPool;
  public setPool(pool: IPool) {
    this._poolAddress = pool.address;
    this.pool = pool;
  }

  public mobileDashboardAssets: ASSETS_TYPE = ASSETS_TYPE.HOME;
  public setDashboardAssetType = (v: ASSETS_TYPE) => (this.mobileDashboardAssets = v);

  public getHealth(poolAddress: string = this.poolAddress) {
    const poolStats: TPoolStats[] = this.getStatsForPool(poolAddress);

    if (!poolStats) return new BN(100);

    const bc = poolStats.reduce((acc: BN, stat, index) => {
      const deposit = BN.formatUnits(stat.selfSupply, stat.decimals);
      const cf = stat.cf;
      if (deposit.eq(0) || !cf) return acc;
      const assetBc = cf.times(1).times(deposit).times(stat.prices.min);
      return acc.plus(assetBc);
    }, new BN(0));
    
    const bcu = poolStats.reduce((acc: BN, stat, index) => {
      const borrow = BN.formatUnits(stat.selfBorrow, stat.decimals);
      const lt = stat.lt;
      if (borrow.eq(0) || !lt) return acc;
      const assetBcu = borrow.times(stat.prices.max).div(lt);
      return acc.plus(assetBcu);
    }, new BN(0));
    
    if (bc.eq(0)) return new BN(100);
    
    const health = new BN(1).minus(bcu.div(bc)).times(100);
    if (health.isNaN() || health.gt(100)) return new BN(100);
    if (health.lte(0)) return BN.ZERO;
    return health;
  }

  public async getHealthAsync(poolAddress: string = this.poolAddress) {
    while (!this._poolsStats || !this.getStatsForPool(poolAddress)) {
      await new Promise(resolve => setTimeout(resolve, updateRate)); // wait 100ms and try again
    }
    const poolStats: TPoolStats[] = this.getStatsForPool(poolAddress);

    const bc = poolStats.reduce((acc: BN, stat, index) => {
      const deposit = BN.formatUnits(stat.selfSupply, stat.decimals);
      const cf = stat.cf;
      if (deposit.eq(0) || !cf) return acc;
      const assetBc = cf.times(1).times(deposit).times(stat.prices.min);
      return acc.plus(assetBc);
    }, new BN(-1));
    const bcu = poolStats.reduce((acc: BN, stat, index) => {
      const borrow = BN.formatUnits(stat.selfBorrow, stat.decimals);
      const lt = stat.lt;
      if (borrow.eq(0) || !lt) return acc;
      const assetBcu = borrow.times(stat.prices.max).div(lt);
      return acc.plus(assetBcu);
    }, new BN(-1));
    const health = new BN(1).minus(bcu.div(bc)).times(100);
    if (health.isNaN() || health.gt(100)) return new BN(100);
    if (health.lte(0)) return BN.ZERO;
    else return health;
  }

  public get health() {
    return this.getHealth();
  }

  public getAccountSupplyBalance(poolAddress: string = this.poolAddress) {
    const poolStats = this.getStatsForPool(poolAddress);
    const tokensSetups = this.getTokensSetups(poolAddress);

    if (!poolStats || !tokensSetups) return BN.ZERO;

    if (this.rootStore.accountStore.address == null) return BN.ZERO;
    return poolStats
      .filter(({ selfSupply }) => selfSupply.gt(0))
      .reduce((acc, v) => {
        const balance = v.prices.max.times(
          BN.formatUnits(v.selfSupply, v.decimals)
        );
        return acc.plus(balance);
      }, BN.ZERO);
  }

  public get accountSupplyBalance() {
    return this.getAccountSupplyBalance();
  }

  public getAccountBorrowBalance(poolAddress: string = this.poolAddress) {
    const poolStats = this.getStatsForPool(poolAddress);
    const tokensSetups = this.getTokensSetups(poolAddress);

    if (!poolStats || !tokensSetups) return BN.ZERO;

    if (this.rootStore.accountStore.address == null) return BN.ZERO;
    return poolStats
      .filter(({ selfBorrow }) => selfBorrow.gt(0))
      .reduce((acc, v) => {
        const balance = v.prices.max.times(
          BN.formatUnits(v.selfBorrow, v.decimals)
        );
        return acc.plus(balance);
      }, BN.ZERO);
  }

  public get accountBorrowBalance() {
    return this.getAccountBorrowBalance();
  }

  public getTotalLiquidity(poolAddress: string = this.poolAddress) {
    const poolStats = this.getStatsForPool(poolAddress);

    if (!poolStats) return BN.ZERO;

    return poolStats.reduce(
      (acc, stat) =>
        BN.formatUnits(stat.totalSupply, stat.decimals)
          .times(stat.prices.min)
          .plus(acc),
      BN.ZERO
    );
  }

  public get totalLiquidity() {
    return this.getTotalLiquidity();
  }

  public getNetApy(poolAddress: string = this.poolAddress) {
    const poolStats = this.getStatsForPool(poolAddress);

    if (!poolStats) return BN.ZERO;

    try {
      const supplyApy = poolStats.reduce(
        (acc, stat) =>
          BN.formatUnits(stat.selfSupply, stat.decimals)
            .times(stat.prices.min)
            .times(stat.supplyAPY)
            .plus(acc),
        BN.ZERO
      );

      const baseAmount = poolStats.reduce(
        (acc, stat) =>
          BN.formatUnits(stat.selfSupply, stat.decimals)
            .times(stat.prices.min)
            .plus(acc),
        BN.ZERO
      );

      const borrowApy = poolStats.reduce(
        (acc, stat) =>
          BN.formatUnits(stat.selfBorrow, stat.decimals)
            .times(stat.prices.min)
            .times(stat.borrowAPY)
            .plus(acc),
        BN.ZERO
      );

      return baseAmount.eq(0)
        ? BN.ZERO
        : supplyApy.minus(borrowApy).div(baseAmount);
    } catch (e) {
      return BN.ZERO;
    }
  }

  public get netApy() {
    return this.getNetApy();
  }

  public getAccountSupply(poolAddress: string = this.poolAddress): TPoolStats[] {
    const poolStats = this.getStatsForPool(poolAddress);

    if (!poolStats) return [];

    if (this.rootStore.accountStore.address == null) return [];
    return poolStats.filter(({ selfSupply }) => selfSupply.gt(0));
  }

  public get accountSupply() { 
    return this.getAccountSupply();
  }

  public getAccountBorrow(poolAddress: string = this.poolAddress): TPoolStats[] {
    const poolStats = this.getStatsForPool(poolAddress);
    if (!poolStats) return [];
    if (this.rootStore.accountStore.address == null) return [];
    return poolStats.filter(({ selfBorrow }) => selfBorrow.gt(0));
  }

  public get accountBorrow() {
    return this.getAccountBorrow();
  }

  public async getTotalSupply(poolAddress: string = this.poolAddress): Promise<BN> {
    while (!this._poolsStats || !this._poolsStats[poolAddress]) {
      await new Promise(resolve => setTimeout(resolve, updateRate)); // wait 100ms and try again
    }
  
    const poolStats = this._poolsStats[poolAddress];
  
    return poolStats.reduce((acc, stat) => {
      const formattedValue = BN.formatUnits(stat.totalSupply, stat.decimals);
      const multipliedValue = formattedValue.times(stat.prices.min);
      return multipliedValue.plus(acc);
    }, BN.ZERO);
  }

  public get totalSupply() {
    return this.getTotalSupply();
  }

  public async getOverallSupply() {
    const resultSupply = POOLS.reduce(async (acc, pool) => {
      const totalSupply = await this.getTotalSupply(pool.address);

      return (await acc).plus(totalSupply);
    }, Promise.resolve(BN.ZERO));

    return resultSupply;
  }

  public async getTotalBorrow(poolAddress: string = this.poolAddress): Promise<BN> {
    while (!this._poolsStats || !this._poolsStats[poolAddress]) {
      await new Promise(resolve => setTimeout(resolve, updateRate)); // wait 100ms and try again
    }
  
    const poolStats = this._poolsStats[poolAddress];
  
    return poolStats.reduce((acc, stat) => {
      const formattedValue = BN.formatUnits(stat.totalBorrow, stat.decimals);
      const multipliedValue = formattedValue.times(stat.prices.min);
      return multipliedValue.plus(acc);
    }, BN.ZERO);
  }

  public get totalBorrow() {
    return this.getTotalBorrow();
  }

  public async getOverallBorrow() {
    const resultBorrow = POOLS.reduce(async (acc, pool) => {
      const totalBorrow = await this.getTotalBorrow(pool.address);

      return (await acc).plus(totalBorrow);
    }, Promise.resolve(BN.ZERO));

    return resultBorrow;
  }

  public async getTotalAccountSupply(poolAddress: string = this.poolAddress): Promise<BN> {
    while (!this._poolsStats || !this._poolsStats[poolAddress]) {
      await new Promise(resolve => setTimeout(resolve, updateRate)); // wait 100ms and try again
    }
  
    const poolStats = this._poolsStats[poolAddress];
  
    return poolStats.reduce((acc, stat) => {
      const formattedValue = BN.formatUnits(stat.selfSupply, stat.decimals);
      const multipliedValue = formattedValue.times(stat.prices.min);
      return multipliedValue.lte(0) ? acc : multipliedValue.plus(acc.lte(0) ? BN.ZERO : acc);
    }, new BN(-1));
  }

  public get totalAccountSupply() {
    return this.getTotalSupply();
  }

  public async getTotalAccountBorrow(poolAddress: string = this.poolAddress): Promise<BN> {
    while (!this._poolsStats || !this._poolsStats[poolAddress]) {
      await new Promise(resolve => setTimeout(resolve, updateRate)); // wait 100ms and try again
    }
  
    const poolStats = this._poolsStats[poolAddress];
  
    return poolStats.reduce((acc, stat) => {
      const formattedValue = BN.formatUnits(stat.selfBorrow, stat.decimals);
      const multipliedValue = formattedValue.times(stat.prices.min);
      return multipliedValue.lte(0) ? acc : multipliedValue.plus(acc.lte(0) ? BN.ZERO : acc);
    }, new BN(-1));
  }

  public get totalAccountBorrow() {
    return this.getTotalBorrow();
  }

  public async getMaxSupplyAPY(poolAddress: string = this.poolAddress): Promise<BN> {
    while (!this._poolsStats || !this._poolsStats[poolAddress]) {
      await new Promise(resolve => setTimeout(resolve, updateRate)); // wait 100ms and try again
    }

    const poolStats = this.getStatsForPool(poolAddress);

    return poolStats.reduce((acc, stat) => {
      const currentAPY = stat.supplyAPY;
      return acc.gt(currentAPY) ? acc : currentAPY;
    }, BN.ZERO);
  }

  public get maxSupplyAPY() {
    return this.getMaxSupplyAPY();
  }
}

export default LendStore;

