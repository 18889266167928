import React, { useMemo, useEffect } from "react";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import { Column } from "@src/components/Flex";
import { TPoolStats } from "@src/stores/LendStore";
import { DashboardUseVM } from "./DashboardModalVM";
import { IIndexToken, ROME_OPERATION_TYPES } from "@src/constants";

import RepayAssets from "./RepayAssets";
import SupplyAssets from "./SupplyAssets";
import WithdrawAssets from "./WithdrawAssets";
import MintAssets from "./MintAssets";
import BuyIndex from "./BuyIndex";
import { useNavigate } from "react-router-dom";
import BN from "@src/utils/BN";

type UrlParamsTypes = {
  tokenId?: string;
  poolId?: string;
  tokenInIndexId?: string; // Добавьте этот параметр, если он используется
};

interface IProps {
  urlParams: UrlParamsTypes;
  operationName: ROME_OPERATION_TYPES;
  tokenStats: TPoolStats;
  indexToken?: IIndexToken;
  onClose: () => void;
}

const Root = styled(Column)`
  width: 100%;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.white};

  & > * {
    width: 100%;
  }
`;

const DashboardModalBody: React.FC<IProps> = ({
  operationName,
  tokenStats,
  indexToken,
  urlParams,
  onClose,
}) => {
  const vm = useMemo(() => DashboardUseVM(), []);
  const navigate = useNavigate();

  // Убедитесь, что tokenInIndex устанавливается при изменении urlParams.tokenInIndexId
  useEffect(() => {
    if (urlParams.tokenInIndexId) {
      vm.setTokenInIndexId(urlParams.tokenInIndexId);
    }
  }, [urlParams.tokenInIndexId, vm]);

  const buyIndexClose = function() {
    navigate(`/${urlParams?.poolId || vm.currentPoolId}/${ROME_OPERATION_TYPES.SUPPLY}/${urlParams?.tokenId}`);
    vm.setDashboardModalStep(1);
    vm.setOperationName(ROME_OPERATION_TYPES.SUPPLY);
    vm.setVMamount(BN.ZERO);
  }

  return (
    <Root>
      {operationName === ROME_OPERATION_TYPES.SUPPLY && (
        <SupplyAssets
          token={tokenStats}
          poolId={urlParams?.poolId || vm.currentPoolId}
          modalAmount={vm.modalAmount}
          modalSetAmount={vm.setVMamount}
          onMaxClick={vm.triggerMaxClickFunc}
          onSubmit={vm.submitSupply}
          onClose={onClose}
        />
      )}
      {operationName === ROME_OPERATION_TYPES.BUY_INDEX && (
        <BuyIndex
          index={indexToken}
          token={tokenStats}
          poolId={urlParams?.poolId || vm.currentPoolId}
          modalAmount={vm.modalAmount}
          modalSetAmount={(amount: BN) => vm.setVMamount(amount)}
          onMaxClick={vm.triggerMaxClickFunc}
          onSubmit={async () => {
            if (!vm.tokenInIndex) {
              console.error("tokenInIndex is undefined");
              return false;
            }
            return await vm.submitBuyIndex();
          }}
          onClose={buyIndexClose}
        />
      )}
      {operationName === ROME_OPERATION_TYPES.WITHDRAW && (
        <WithdrawAssets
          token={tokenStats}
          poolId={urlParams?.poolId || vm.currentPoolId}
          userHealth={vm.userHealth}
          modalAmount={vm.modalAmount}
          modalSetAmount={vm.setVMamount}
          onMaxClick={vm.triggerMaxClickFunc}
          onSubmit={vm.submitWithdraw}
          onClose={onClose}
        />
      )}
      {operationName === ROME_OPERATION_TYPES.MINT && (
        <MintAssets
          token={tokenStats}
          poolId={urlParams?.poolId || vm.currentPoolId}
          userHealth={vm.userHealth}
          modalAmount={vm.modalAmount}
          modalSetAmount={vm.setVMamount}
          onMaxClick={vm.triggerMaxClickFunc}
          onSubmit={vm.submitBorrow}
          onClose={onClose}
        />
      )}
      {operationName === ROME_OPERATION_TYPES.REPAY && (
        <RepayAssets
          token={tokenStats}
          poolId={urlParams?.poolId || vm.currentPoolId}
          modalAmount={vm.modalAmount}
          modalSetAmount={vm.setVMamount}
          onMaxClick={vm.triggerMaxClickFunc}
          onSubmit={vm.submitRepay}
          onClose={onClose}
        />
      )}
    </Root>
  );
};
export default observer(DashboardModalBody);