import React, { useCallback } from "react";
import { Row, Column } from "@src/components/Flex";
import tokenLogos from "@src/constants/tokenLogos";
import { observer } from "mobx-react-lite";
import Text from "@components/Text";
import shared from "@src/assets/icons/shared.svg";
import petal from "@src/assets/icons/petal.svg";
import styled from "@emotion/styled";
import Dropdown from "@src/components/Dropdown";
import useWindowSize from "@src/hooks/useWindowSize";
import Button from "@src/components/Button";
import { useNavigate } from "react-router-dom";
import { OPERATION_STATUS, POOLS, TOKENS_LIST } from "@src/constants";
import { useStores } from "@stores";

const Container = styled(Row)<{color: string}>`
  position: relative;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 8px;
  width: 140px;
  height: 100%;
  align-items: center;
  cursor: pointer;
  background: ${({ theme, color }) => color === 'white' ? theme.colors.white : theme.colors.blue500};
  @media (max-width: 880px) {
    width: 100%;
    height: 56px;
  }
`;

const RomeLogo = styled.img`
    width: 124px;
    height: 124px;
    @media (max-width: 880px) {
        width: 56px;
        height: 56px;
    }
`

const RomeContainer = styled(Row)`
    gap: 20px;
    height: 124px;
    margin: 20px 0px 20px 0px;
`

const RomeContainerMobile = styled(Column)`
    gap: 20px;
    margin: 20px 0px 20px 0px;
`

const Descripton = styled(Row)`
    background: ${({ theme }) => theme.colors.white};
    width: 55%;
    height: 100%;
    border-radius: 16px;
    padding: 14px 24px;
    box-sizing: border-box;
    @media (max-width: 880px) {
        width: 100%;
    }
`

const SharedIcon = styled.img`
    position: absolute;
    top: 10px;
    right: 10px;
    &:hover {
        cursor: pointer;
    }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Root = styled.div`
`

const StyledCard = styled.div`
    width: 25%;
    height: 130px;
    background: ${({ theme }) => theme.colors.primary50};
    padding: 20px;
    border-radius: 16px;
    position: relative;
    @media (max-width: 880px) {
        width: 100%;
        box-sizing: border-box;
    }
`

const PetalContainer = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 16px;
    img {
        border-top-right-radius: 16px;
    }
    p {
        top: 5px;
        right: -12px;
        position: absolute;
    }
`
const data = [
    {
        title: "Obtain a PZ-Token*",
        descripton: "Acquire any PZ-Token (e.g., PZ WEBu) through an aggregator or directly from its megapool"
    },
    {
        title: "Supply to Puzzle Lend",
        descripton: "Deposit your PZ-Token into Puzzle Lend as collateral"
    },
    {
        title: "Mint ROME",
        descripton: "If your health level drops to zero, liquidation occurs. 20% health level is considered safe"
    },
    {
        title: "Hold & Earn Rewards",
        descripton: "Keep your ROME and receive payouts every few hours or use it in DeFi products"
    }
]

const generateWrapper = (width: number | undefined) => {
    return (
        <Wrapper>
                <Dropdown title="Learn about ROME minting process">
                    {width && width < 880 ? (
                        <Column style={{gap: 10, marginBottom: 15, width: "100%"}}>
                            {data.map((el, key) => (
                                <StyledCard>
                                    <PetalContainer>
                                        <img src={petal} />
                                        <Text style={{color: "#ffffff"}}>{key + 1}</Text>
                                    </PetalContainer>
                                    <Text weight={500} style={{fontSize: 17}}>{el.title}</Text>
                                    <Text style={{marginTop: 10, fontSize: 14}}>{el.descripton}</Text>
                                </StyledCard>
                            ))}
                        </Column>
                    ) : (
                        <Row style={{gap: 10, marginBottom: 15,  width: "100%"}}>
                            {data.map((el, key) => (
                                <StyledCard>
                                    <PetalContainer>
                                        <img src={petal} />
                                        <Text style={{color: "#ffffff"}}>{key + 1}</Text>
                                    </PetalContainer>
                                    <Text weight={500} style={{fontSize: 17}}>{el.title}</Text>
                                    <Text style={{marginTop: 10, fontSize: 14}}>{el.descripton}</Text>
                                </StyledCard>
                            ))}
                        </Row>
                    )}
                    <Text size="medium" type="secondary">*PZ-Tokens are LP tokens of Puzzle Megapools consisting of multiple underlying assets. For example, PZ WEBu is based on WAVES, ETHu and BTCu.</Text>
                </Dropdown>
            </Wrapper>
    )
}
const RomeInfoContainer = () => {
    const { lendStore } = useStores();
    const { width } = useWindowSize();
    const navigate = useNavigate();
    const assetId = TOKENS_LIST.find((el) => el.symbol === "ROME")?.assetId;
    const poolId = POOLS.find((el) => el.name === "ROME Market")?.address;
    const openModal = useCallback(
        (
          operationName: string,
        ) => {
          return navigate(`/3P8Df2b7ywHtLBHBe8PBVQYd3A5MdEEJAou/${operationName}/AP4Cb5xLYGH6ZigHreCZHoXpQTWDkPsG2BHqfDUx6taJ`);
        },
        [navigate]
      );
      
    return (
        <Root>
            {width && width < 880 ? (
                <RomeContainerMobile>
                    <Row style={{gap: 10}}>
                        <RomeLogo src={tokenLogos["ROME"]} />
                        <Button style={{width: '100%'}} kind="secondary" onClick={() => window.open("https://romedash.puzzleswap.org/", "_blank")}><Text>ROME Statistics</Text> <img src={shared} /></Button>
                    </Row>
                    <Descripton>
                        <Text weight={500} type="secondary">ROME is a stablecoin on the Waves blockchain. It is backed by yield-generating LP tokens of Puzzle Swap, which enables a unique feature: organic APY generation. This is the only page where one can mint ROME using one of the PZ-Tokens.</Text>
                    </Descripton>
                    <Container color={'dark'} onClick={(e) => openModal("borrow")}>
                        <Text size="medium" weight={500} textAlign="center" style={{ color: "#ffffff" }}>Mint ROME</Text>
                    </Container>
                </RomeContainerMobile>
            ) : (
                <RomeContainer>
                    <RomeLogo src={tokenLogos["ROME"]} />
                    <Descripton>
                        <Text weight={500} type="secondary">ROME is a stablecoin on the Waves blockchain. It is backed by yield-generating LP tokens of Puzzle Swap, which enables a unique feature: organic APY generation. This is the only page where one can mint ROME using one of the PZ-Tokens.</Text>
                    </Descripton>
                    <Container color={'white'} onClick={() => window.open("https://romedash.puzzleswap.org/", "_blank")}>
                        <SharedIcon src={shared} />
                        <Text size="medium" type="blue500" weight={500} textAlign="center">ROME Statistics</Text>
                    </Container>
                    <Container color={'dark'} onClick={(e) => {lendStore.setOperationType(OPERATION_STATUS.MINT); openModal("mint")}}>
                        <Text size="medium" weight={500} textAlign="center" style={{ color: "#ffffff" }}>Mint ROME</Text>
                    </Container>
                </RomeContainer>
            )}
            {generateWrapper(width)}
        </Root>
    )
}

export default observer(RomeInfoContainer);

