import React, { useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { Row } from "@components/Flex";
import SizedBox from "@components/SizedBox";
import SwitchButtons from "./components/SwitchButtons";
import { OPERATION_STATUS, ROME_OPERATION_TYPES, TOKENS_LIST } from "@src/constants";
import { useStores } from "@stores";
import Skeleton from "react-loading-skeleton";
import DashboardModalBody from "./DashboardModalBody";
import Dialog from "@components/Dialog";
import {
  DashboardVMProvider,
  DashboardUseVM
} from "./DashboardModalVM";
import { WalletVMProvider } from "@src/components/Wallet/WalletModal/WalletVM";
import Text from "@components/Text";

interface IProps {}

interface IPropsVM {
  operationName: ROME_OPERATION_TYPES;
}

const TabsWrapper = styled(Row)`
  border-radius: 16px 16px 0px 0px;
  height: 56px;
  margin-top: -56px;
`;

const RomeDashboardModalContent: React.FC<IProps> = () => {
  const vm = DashboardUseVM();
  const { lendStore } = useStores();
  const navigate = useNavigate();
  const [getModalTitles, setModalTitles] = useState<string[]>(["", ""]);
  const [isOpen, setOpen] = useState<boolean>(false);

  useMemo(() => {
    const supplyTitles: [string, string, string] = ["1 Buy PZ", "2 Supply", "3 Withdraw"];
    const MINTTitles: [string, string, string] = ["1 Buy PZ", "2 Supply", "3 Mint"];
    const RepayTitles: [string] = ["Repay"];
    if (lendStore.operationType === OPERATION_STATUS.BUY) {
      setModalTitles(supplyTitles);
    } else if (lendStore.operationType === OPERATION_STATUS.MINT) {
      setModalTitles(MINTTitles);
    } else if (lendStore.operationType === OPERATION_STATUS.REPAY) {
      setModalTitles(RepayTitles);
    }
    // if (
    //   [ROME_OPERATION_TYPES.BUY_INDEX, ROME_OPERATION_TYPES.SUPPLY, ROME_OPERATION_TYPES.WITHDRAW].includes(
    //     vm.operationName
    //   )
    // ) {
    //   setModalTitles(supplyTitles);
    // } else {
    //   setModalTitles(MINTTitles);
    // }


    if (lendStore.operationType === OPERATION_STATUS.BUY) {
      const operations = [ROME_OPERATION_TYPES.BUY_INDEX, ROME_OPERATION_TYPES.SUPPLY, ROME_OPERATION_TYPES.WITHDRAW];
      vm.setDashboardModalStep(operations.indexOf(vm.operationName));
    } else if (lendStore.operationType === OPERATION_STATUS.MINT) {
      const operations = [ROME_OPERATION_TYPES.BUY_INDEX, ROME_OPERATION_TYPES.SUPPLY, ROME_OPERATION_TYPES.MINT];
      vm.setDashboardModalStep(operations.indexOf(vm.operationName));
    }

    setOpen(true);
  }, [lendStore.operationType, vm]);

  const setActiveTab = (step: number) => {
    vm.setDashboardModalStep(step);
    let operations: string | any[]
    if (lendStore.operationType === OPERATION_STATUS.BUY) {
      operations = [ROME_OPERATION_TYPES.BUY_INDEX, ROME_OPERATION_TYPES.SUPPLY, ROME_OPERATION_TYPES.WITHDRAW];
    } else if (lendStore.operationType === OPERATION_STATUS.MINT) {
      operations = [ROME_OPERATION_TYPES.BUY_INDEX, ROME_OPERATION_TYPES.SUPPLY, ROME_OPERATION_TYPES.MINT];
    } else {
      operations = []
    }

    if (operations.includes(vm.operationName)) {
      let operation = operations[step];
      
      vm.setDashboardModalStep(operations.indexOf(vm.operationName));

      let indexStats = TOKENS_LIST.find((i) => i.assetId == vm.urlParams?.tokenId)
      if (indexStats?.symbol === "ROME") indexStats = lendStore.poolsStats.slice().filter(a => a.paused.eq(0) && a.symbol !== "ROME")[2]
      const tokenInIndexId = indexStats?.underlying?.[0];
      let firstToken = indexStats?.symbol === "ROME" ? vm.urlParams?.tokenId : indexStats?.assetId
      if (lendStore.operationType === 'mint' && operation === 'mint' || lendStore.operationType === 'repay' && operation === 'repay') {
        firstToken = 'AP4Cb5xLYGH6ZigHreCZHoXpQTWDkPsG2BHqfDUx6taJ'
        operation = ROME_OPERATION_TYPES.MINT
      }
      return navigate(
        `/${lendStore.poolId}/${operation}/${firstToken}` + (operation === ROME_OPERATION_TYPES.BUY_INDEX && tokenInIndexId ? `/${tokenInIndexId}` : "")
      );
    }

    operations = [ROME_OPERATION_TYPES.BUY_INDEX, ROME_OPERATION_TYPES.SUPPLY, ROME_OPERATION_TYPES.MINT];

    if (operations.includes(vm.operationName)) {
      const operation = operations[step];

      vm.setDashboardModalStep(operations.indexOf(vm.operationName));
      return navigate(
        `/${lendStore.poolId}/${operation}/${vm.urlParams?.tokenId}`
      );
    }
  };

  const closeTab = () => navigate(`/${lendStore.poolId}`);

  return (
    <Dialog
      wrapClassName="dashboard-dialog"
      title={lendStore.operationType === OPERATION_STATUS.MINT || lendStore.operationType === OPERATION_STATUS.REPAY  ? "Mint ROME" : "Operations"}
      visible={isOpen}
      onClose={() => closeTab()}
      style={{ maxWidth: "415px" }}
    >
      <SizedBox height={72} />
      <TabsWrapper>
        <SwitchButtons
          values={getModalTitles}
          active={vm.dashboardModalStep}
          onActivate={(v: number) => setActiveTab(v)}
          border
        />
      </TabsWrapper>
      {lendStore.operationType === OPERATION_STATUS.MINT && (
        <Text size="medium" type="secondary" fitContent>
          You need a PZ-Asset as collateral to mint ROME, unlocking liquidity without selling your holdings while earning rewards.
        </Text>
      )}
      {vm.token && (
        <DashboardModalBody
          urlParams={vm.urlParams}
          operationName={vm.operationName}
          tokenStats={vm.token}
          indexToken={vm.indexToken}
          onClose={closeTab}
        />
      )}
    </Dialog>
  );
};

const RomeDashboardModal: React.FC<IPropsVM> = ({ operationName }) => {
  const urlParams = useParams<{ tokenId: string; tokenInIndexId: string; poolId: string }>();
  const { lendStore } = useStores();
  return (
    <WalletVMProvider>
      <DashboardVMProvider operationName={operationName} urlParams={urlParams}>
        {lendStore.initialized ? (
          <RomeDashboardModalContent />
        ) : (
          <Dialog
            wrapClassName="dashboard-dialog"
            title="Operations"
            visible={true}
            style={{ maxWidth: "415px" }}
          >
            <Skeleton height={56} style={{ marginBottom: 8 }} count={4} />
          </Dialog>
        )}
      </DashboardVMProvider>
    </WalletVMProvider>
  );
};

export default observer(RomeDashboardModal);
