/* eslint-disable react/jsx-pascal-case */
import React, { useMemo, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import Tooltip from "@components/Tooltip";
import Table from "@components/Table";
import { OPERATION_STATUS, ROUTES, TOKENS_LIST } from "@src/constants";
import { Column, Row } from "@src/components/Flex";
import Text from "@src/components/Text";
import SquareTokenIcon from "@components/SquareTokenIcon";
import SizedBox from "@components/SizedBox";
import Button from "@src/components/Button";
import { useStores } from "@stores";
import { observer } from "mobx-react-lite";
import BN from "@src/utils/BN";
import Skeleton from "react-loading-skeleton";
import { useTheme } from "@emotion/react";
import { TPoolStats } from "@src/stores/LendStore";
import { tokensUnderlying } from "@src/constants/tokensUnderlying";

type ISortTypes = "totalSupply" | "supplyAPY" | "totalBorrow" | "borrowAPY";

interface IProps {}

const Root = styled.div<{ sort?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 96px;

  .sort-icon {
    width: 20px;
    height: 20px;
    margin-left: 4px;
    transform: ${({ sort }) => (sort ? "scale(1)" : "scale(1, -1)")};
  }
`;

const SupplyApy = styled.div`
  display: flex;
  white-space: nowrap;

  img {
    margin: 1px 4px 0 -1px;
  }
`;

const TooltipText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  max-width: 180px;
  white-space: normal;
`;

const StyledAsset = styled.img<{index: number}>`
  width: 31px;
  height: 31px;
  border-radius: 100px;
  margin-right: -${props => props.index + 10}px;
`

const TooltipIcon = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 100px;
`

const DesktopTable: React.FC<IProps> = () => {
  const { poolId } = useParams<{ poolId: string }>();
  const { lendStore } = useStores();
  const theme = useTheme();
  const [assets, setAssets] = useState<any[]>([]);
  const [filteredAssets, setFilteredAssets] = useState<any[]>([]);
  const [sortMode, setActiveSortMode] = useState<"descending" | "ascending">(
    "descending"
  );
  const [sort, setActiveSort] = useState<ISortTypes>("totalSupply");

  const selectSort = useCallback(
    (v: ISortTypes) => {
      if (sort === v) {
        setActiveSortMode(
          sortMode === "ascending" ? "descending" : "ascending"
        );
      } else {
        setActiveSort(v);
        setActiveSortMode("descending");
      }
    },
    [sortMode, sort]
  );
  const navigate = useNavigate();

  const isSupplyDisabled = useCallback((token: TPoolStats) => {
    if (token?.supplyLimit.eq(0)) return false;
    if (!token?.totalSupply || !token?.totalBorrow) return false;
    const reserves = BN.formatUnits(
      token?.totalSupply?.minus(token?.totalBorrow),
      token?.decimals
    );

    return reserves.gt(token?.supplyLimit.div(token.prices.min));
  }, []);

  const columns = useMemo(
    () => [
      { Header: "Index", accessor: "index" },
      { Header: () => <Row justifyContent="start">Assets</Row>, accessor: "assets" },
      {
        Header: () => (
          <Row
            style={{ cursor: "pointer" }}
            onClick={() => selectSort("totalSupply")}
            justifyContent="flex-end"
          >
            <Tooltip
              content={
                <Text textAlign="left">
                  Amount of deposited tokens in total.
                </Text>
              }
            >
              <Text
                style={{ textDecoration: "underline dotted" }}
                size="medium"
                fitContent
                nowrap
              >
                Total supply
              </Text>
            </Tooltip>
            <img
              src={theme.images.icons.group}
              alt="group"
              className="sort-icon"
            />
          </Row>
        ),
        accessor: "supply"
      },
      {
        Header: () => (
          <Row
            style={{ cursor: "pointer" }}
            onClick={() => selectSort("supplyAPY")}
            justifyContent="flex-end"
          >
            <Tooltip
              content={
                <Text textAlign="left">
                  Annualized interest earned by depositors
                </Text>
              }
            >
              <Text
                style={{ textDecoration: "underline dotted" }}
                size="medium"
                fitContent
                nowrap
              >
                Supply APY
              </Text>
            </Tooltip>
            <img
              src={theme.images.icons.group}
              alt="group"
              className="sort-icon"
            />
          </Row>
        ),
        accessor: "supplyApy"
      },
      { Header: "", accessor: "buyIndexBtn" },
      { Header: "", accessor: "supplyBtn" },
    ],
    [theme.images.icons.group, selectSort]
  );

  const openModal = useCallback(
    (
      e: React.MouseEvent,
      poolId: string,
      operationName: string,
      assetId: string,
      tokenInIndexId?: string,
    ) => {
      e.stopPropagation();
      return navigate(`/${poolId}/${operationName}/${assetId}` + ((tokenInIndexId && `/${tokenInIndexId}`) || ""));
    },
    [navigate]
  );

  useMemo(() => {
    let data: any = lendStore.poolsStats.slice().filter(a => a.paused.eq(0) && a.symbol !== "ROME");

    data = data.map((s: TPoolStats) => {

      let indexStats = TOKENS_LIST.find((i) => i.assetId === s.assetId);
      return {
        s,
        onClick: () => {
          navigate(
            ROUTES.DASHBOARD_TOKEN_DETAILS.replace(
              ":poolId",
              lendStore.pool.address
            ).replace(":assetId", s.assetId)
          );
        },
        index: (
          <Row alignItems="center">
            <SquareTokenIcon size="small" src={s.logo} alt="logo" />
            <SizedBox width={16} />
            <Column>
              <Text size="small">
                {s.symbol}
              </Text>
              <Text type="secondary" size="small" fitContent>
                $ {s.prices.min.toBigFormat(4)}
              </Text>
            </Column>
          </Row>
        ),
        assets: (
          <Tooltip
          config={{
            placement: 'top'
          }}
          content={
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: 300 }}>
              {s?.underlying && s?.underlying.map((el, key) => {
                const token = tokensUnderlying[el as keyof typeof tokensUnderlying];
                return (
                  <Row style={{ flex: 1, minWidth: '50%', gap: 5 }}>
                    <TooltipIcon style={{width: 20}} src={typeof token === 'string' ? token : token.icon} />
                    <Text textAlign="left">{typeof token === 'string' ? token : token.name.length > 10 ? `${token.name.substring(0, 10)}...` : token.name}</Text>
                  </Row>
                )}
              )}
            </div>
          }
        >
          <Row>
              {s?.underlying && s?.underlying.map((el, key) => {
                const token = tokensUnderlying[el as keyof typeof tokensUnderlying];
                return <StyledAsset index={key} src={typeof token === 'string' ? token : token.icon} />;
              })}
          </Row>
        </Tooltip>
        ),
        supply: (
          <Column crossAxisSize="max">
            <Text weight={500} textAlign="right" size="medium">
              {BN.formatUnits(s.totalSupply, s.decimals).toBigFormat(2) +
                ` ${s.symbol}`}
            </Text>
            <Text textAlign="right" size="small" type="secondary">
              ${" "}
              {BN.formatUnits(s.totalSupply, s.decimals)
                .times(s.prices.min)
                .toBigFormat(4)}
            </Text>
          </Column>
        ),
        supplyApy: (
          <SupplyApy>
            <Tooltip
              config={{ placement: "top-start" }}
              content={
                <TooltipText textAlign="left">
                  Non-borrowed tokens are automatically staked within native
                  protocol to generate additional interest
                </TooltipText>
              }
            >
              {s.isAutostakeAvl && (
                <img
                  src={theme.images.icons.autostaking}
                  alt="autostaking"
                  className="autostaking-icon"
                />
              )}
            </Tooltip>
            {s.supplyAPY.toFormat(2) + " %"}
          </SupplyApy>
        ),
        buyIndexBtn: (
          <Button
            kind="secondary"
            size="medium"
            fixed
            onClick={(e) => {
              lendStore.setOperationType(OPERATION_STATUS.BUY)
              openModal(e, lendStore.poolId, "buyIndex", s.assetId, indexStats?.underlying?.[0])
            }}
            style={{ width: "100px", margin: "0 auto" }}
          >
            Buy PZ
          </Button>
        ),
        supplyBtn: isSupplyDisabled(s) ? (
          <Tooltip
            content={
              <Text textAlign="left">Maximum total supply is reached</Text>
            }
          >
            <Button
              kind="secondary"
              size="medium"
              fixed
              disabled={true}
              onClick={(e) => {lendStore.setOperationType(OPERATION_STATUS.BUY); openModal(e, lendStore.poolId, "supply", s.assetId)}}
              style={{ width: "100px", margin: "0 auto" }}
            >
              Supply
            </Button>
          </Tooltip>
        ) : (
          <Button
            kind="secondary"
            size="medium"
            fixed
            onClick={(e) => {lendStore.setOperationType(OPERATION_STATUS.BUY); openModal(e, lendStore.poolId, "supply", s.assetId)}}
            style={{ width: "100px", margin: "0 auto" }}
          >
            Supply
          </Button>
        ),
      }
    });

    setAssets(data);
  }, [
    poolId,
    lendStore.poolsStats,
    theme.images.icons.autostaking,
    isSupplyDisabled,
    navigate,
    openModal,
  ]);
  
  useMemo(() => {
    let data: any = assets.sort((a, b) => {
      const stats1: TPoolStats = a.s;
      const stats2: TPoolStats = b.s;
      let key: keyof TPoolStats | undefined;
      if (sort === "totalSupply") key = "totalSupply";
      if (sort === "supplyAPY") key = "supplyAPY";
      if (key == null) return 0;

      if (stats1 == null || stats2 == null) return 0;
      if (stats1[key] == null && stats2[key] != null)
        return sortMode === "descending" ? 1 : -1;
      if (stats1[key] == null && stats2[key] == null)
        return sortMode === "descending" ? -1 : 1;

      const stat1 = stats1[key] as keyof TPoolStats;
      const stat2 = stats2[key] as keyof TPoolStats;

      // if filtering in $ equivalent
      if (["totalBorrow", "totalSupply"].includes(sort)) {
        const val1 = (BN.formatUnits(stat1, stats1.decimals) as BN)
          .times(stats1?.prices.min)
          .toDecimalPlaces(0);
        const val2 = (BN.formatUnits(stat2, stats2.decimals) as BN)
          .times(stats2?.prices.min)
          .toDecimalPlaces(0);

        if (sortMode === "descending") return val1.lt(val2) ? 1 : -1;
        else return val1.lt(val2) ? -1 : 1;
      }

      if (sortMode === "descending") {
        return BN.formatUnits(stat1, 0).lt(stat2) ? 1 : -1;
      } else return BN.formatUnits(stat1, 0).lt(stat2) ? -1 : 1;
    });

    setFilteredAssets(data);
  }, [
    assets,
    sort,
    sortMode,
  ]);

  return (
    <Root sort={sortMode === "descending"}>
      {lendStore.initialized && assets.length ? (
        <Table columns={columns} data={assets} />
      ) : (
        <Skeleton height={56} style={{ marginBottom: 8 }} count={4} />
      )}
    </Root>
  );};
export default observer(DesktopTable);
