import styled from "@emotion/styled";
import React from "react";
import { observer } from "mobx-react-lite";
import MobileAccountSupply from "./MobileAccountSupply";
import { SupplyAndBorrowVMProvider } from "../DesktopAccountSupplyAndBorrow/SupplyAndBorrowVM";

interface IProps {}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const MobileAccountSupplyAndBorrowImpl: React.FC<IProps> = () => {
  return (
    <Root>
       <MobileAccountSupply />
    </Root>
  );
};

const MobileAccountSupplyAndBorrow: React.FC = () => {
  return (
    <SupplyAndBorrowVMProvider>
      <MobileAccountSupplyAndBorrowImpl />
    </SupplyAndBorrowVMProvider>
  );
};

export default observer(MobileAccountSupplyAndBorrow);
