import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useStores } from "@src/stores";
import { useNavigate } from "react-router-dom";
import SizedBox from "@components/SizedBox";
import Text from "@components/Text";
import Button from "@components/Button";
import { observer } from "mobx-react-lite";
import { Row } from "@components/Flex";
import { TPoolStats } from "@src/stores/LendStore";
import { DashboardUseVM } from "./DashboardModalVM";
import { IIndexToken, ROME_OPERATION_TYPES, ROUTES } from "@src/constants";
import BN from "@src/utils/BN";
import _ from "lodash";

import {
  Footer,
  Root
} from "@src/screens/Dashboard/DashboardModals/components/ModalContent";
import Warning from "@src/screens/Dashboard/DashboardModals/components/Warning";
import TokenInput from "@src/components/TokenInput";
import { useWalletVM } from "@src/components/Wallet/WalletModal/WalletVM";
import { reaction } from "mobx";
import { useTheme } from "@emotion/react";
import ModalTokenInput from "./components/ModalTokenInput";

interface IProps {
  index?: IIndexToken;
  token: TPoolStats;
  poolId: string;
  modalAmount: BN;
  modalSetAmount: (amount: BN) => void;
  onMaxClick: (amount: BN) => void;
  onSubmit: () => Promise<boolean>;
  onClose: () => void;
}

const BuyIndex: React.FC<IProps> = ({
  index,
  token,
  modalAmount,
  poolId,
  onClose,
  modalSetAmount,
  onMaxClick,
  onSubmit
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const vm = DashboardUseVM();
  const walletVm = useWalletVM();
  const [amount, setAmount] = useState<BN>(modalAmount);
  const { accountStore, lendStore } = useStores();

  useEffect(() => {
    modalAmount && setAmount(modalAmount);
  }, [modalAmount]);

  const debounce = useMemo(
    () => _.debounce((val: BN) => modalSetAmount(val), 500),
    [modalSetAmount]
  );

  const handleDebounce = useCallback(
    (val: BN) => {
      setAmount(val);
      debounce(val);
    },
    [debounce]
  );

  const handleChangeAmount = (v: BN) => {
    vm.buyIndexChangeAmount(v);
    handleDebounce(v);
  };

  const handleChangeAssetId = (v: string) => {
    const newPath = (
      ROUTES.DASHBOARD_ROME
      + "/"
      + ROUTES
        .ROME_MODALS[ROME_OPERATION_TYPES.BUY_INDEX]
        .replace(":tokenId", token.assetId)
        .replace(":tokenInIndexId", v));

    vm.setTokenInIndexId(v);
    
    navigate(newPath, { replace: true });
  }

  const handleChangeQuoteAssetId = (v: string) => {
    const newPath = (
      ROUTES.DASHBOARD_ROME
      + "/"
      + ROUTES
        .ROME_MODALS[ROME_OPERATION_TYPES.BUY_INDEX]
        .replace(":tokenId", v)
        .replace(":tokenInIndexId", assetId0));

    vm.setTokenInIndexId(v);
    
    navigate(newPath, { replace: true });
  }

  const getMaxSupply = () => {
    const val = vm.countMaxBtn.toDecimalPlaces(0);
    handleDebounce(val);

    return val;
  };

  const submitForm = async () => {
    const isSuccess = await onSubmit();

    if (isSuccess) onClose();
  };
  const balances = walletVm.balances.filter((b) => vm.indexToken?.underlying?.find((t: any) => t === b.assetId));
  const pzBalances = walletVm.balances.filter((b) => lendStore.poolsStats?.find((t: any) => t.assetId === b.assetId && b.symbol !== "ROME"));

  const assetId0 = useMemo(() => {
    return vm.tokenInIndex?.assetId || "";
  }, [vm.tokenInIndex?.assetId]);

  const decimals0 = useMemo(() => {
    return vm.tokenInIndex?.decimals || 9;
  }, [vm.tokenInIndex?.decimals]);

  const[amount1, setAmount1] = useState<BN>(vm.amount1);

  reaction(
    () => [vm.amount1, vm.tokenInIndex],
    () => {
      setAmount1(vm.amount1);
    }
  )

  return (
    <Root>
      <TokenInput
        isShowMaxClick={true}
        balances={balances}
        assetId={assetId0}
        decimals={decimals0}
        amount={BN.ZERO}
        setAmount={handleChangeAmount}
        setAssetId={handleChangeAssetId}
        selectable={true}
      />
      <SizedBox height={12} />
      {/* <img alt="swap" src={theme.images.icons.swap} className="icon" style={{ width: 42, height: 42, margin: "0 7px" }} /> */}
      <SizedBox height={12} />
      <TokenInput
        balances={pzBalances}
        assetId={token.assetId}
        decimals={token?.decimals}
        amount={amount1}
        setAmount={handleChangeAmount}
        setAssetId={handleChangeQuoteAssetId}
        selectable={true}
      />
      <SizedBox height={24} />
      {vm.priceImpact && (
        <Row justifyContent="space-between">
          <Text size="medium" type="secondary" fitContent>
            Price impact
          </Text>
          <Text size="medium" fitContent>
            <Text size="medium">~{vm.priceImpact.toFormat(4)}%&nbsp;</Text>
          </Text>
        </Row>
      )}
      <SizedBox height={6} />
      <Row justifyContent="space-between">
        <Text size="medium" type="secondary" fitContent>
          Transaction fee
        </Text>
        <Text size="medium" fitContent>
          0.005 WAVES
        </Text>
      </Row>
      <SizedBox height={6} />
      <Text size="medium" type="secondary" fitContent>
        Transaction will be made through Puzzle aggregator.
      </Text>
      <SizedBox height={24} />
      {vm.modalWarningText && (
        <>
          <Warning
            text={vm.modalWarningText}
            accentText={
              <div
                onClick={() => onMaxClick(getMaxSupply())}
                style={{ cursor: "pointer" }}
              >
                Supply max to limit
              </div>
            }
          />
          <SizedBox height={24} />
        </>
      )}
      <Footer>
        {accountStore && accountStore.address ? (
          <Button
            disabled={amount.eq(0) || vm.modalBtnErrorText !== ""}
            fixed
            onClick={() => submitForm()}
            size="large"
          >
            {vm.modalBtnErrorText !== "" ? vm.modalBtnErrorText : "Buy " + token?.symbol}
          </Button>
        ) : (
          <Button
            fixed
            onClick={() => {
              accountStore.setLoginModalOpened(true);
            }}
            size="large"
          >
            Login
          </Button>
        )}
      </Footer>
    </Root>
  );
};
export default observer(BuyIndex);
