import tokens from "./tokens.json";
import tokenLogos from "@src/constants/tokenLogos";

export enum OPERATIONS_TYPE {
  WITHDRAW = "withdraw",
  SUPPLY = "supply",
  BORROW = "borrow",
  REPAY = "repay"
}


export enum ROME_OPERATION_TYPES {
  SUPPLY = "supply",
  BUY_INDEX = "buyIndex",
  WITHDRAW = "withdraw",
  REPAY = "repay",
  MINT = "mint",
}

export enum OPERATION_STATUS {
  BUY = "buy",
  MINT = "mint",
  REPAY = "repay",
}

export const ROME_ASSET_ID = "3P8Df2b7ywHtLBHBe8PBVQYd3A5MdEEJAou";

export const CONTRACT_ADDRESSES = {
  x: "3PQoBfUKHkJAeGWhooLP7WS8ovb54av9Jp2",
  aggregator: "3PGFHzVGT4NTigwCKP1NcwoXkodVZwvBuuU",
}

export const ROUTES = {
  ROOT: "/",
  DASHBOARD: "/",
  DASHBOARD_MODAL_WITHDRAW: "",
  DASHBOARD_POOL: "/:poolId",
  DASHBOARD_ROME: "/" + ROME_ASSET_ID,
  DASHBOARD_MODALS: {
    [OPERATIONS_TYPE.SUPPLY]: "supply/:tokenId",
    [OPERATIONS_TYPE.WITHDRAW]: "withdraw/:tokenId",
    [OPERATIONS_TYPE.BORROW]: "borrow/:tokenId",
    [OPERATIONS_TYPE.REPAY]: "repay/:tokenId"
  },
  ROME_MODALS: {
    [ROME_OPERATION_TYPES.SUPPLY]: "supply/:tokenId",
    [ROME_OPERATION_TYPES.BUY_INDEX]: "buyIndex/:tokenId/:tokenInIndexId",
    [ROME_OPERATION_TYPES.WITHDRAW]: "withdraw/:tokenId",
    [ROME_OPERATION_TYPES.REPAY]: "repay/:tokenId",
    [ROME_OPERATION_TYPES.MINT]: "mint/:tokenId"
  },
  DASHBOARD_TOKEN_DETAILS: "/:poolId/:assetId",
  ANALYTICS: "/analytics",
  NOT_FOUND: "/404"
};

export const POOLS_PROD: IPool[] = [
  {
    name: "New Market",
    address: "3PP9NsYNdmXTqt5bseaegRKHUE76F9MHGgW",
    description: "New liquidity hub containing Wavesbridge tokens and ROME.",
  },
  {
    name: "ROME Market",
    address: "3P8Df2b7ywHtLBHBe8PBVQYd3A5MdEEJAou",
    description: "The market used to mint ROME stablecoin by adding LP tokens in collateral. The LPs APY is boosted by donated gWX.",
  },
  {
    name: "Old Market",
    address: "3P4uA5etnZi4AmBabKinq2bMiWU8KcnHZdH",
    description: "The oldest market consisting of most reliable assets and PPT bridge tokens with modest APYs.",
  },
  {
    name: "Waves DeFi",
    address: "3P4DK5VzDwL3vfc5ahUEhtoe5ByZNyacJ3X",
    description: "The second oldest market consisting of different tokens from Waves Defi landscape.",
  },
  {
    name: "Low Cap",
    address: "3PHpuQUPVUoR3AYzFeJzeWJfYLsLTmWssVH",
    description: "The most yield generating market consisting of assets with higher volatility. Use it with caution.",
  },
  // {
  //   name: "Axly Market",
  //   address: "3PAd9Aqg3cQSzu26MFYWQYcjSXPVCJtuf5V"
  // },
  // {
  //   name: "Global Pool",
  //   address: "3PJPpKuM7NTC9QaDqAPY4V8wtRWWmLBSSsY"
  // },
];

export const POOLS_DEV = POOLS_PROD;
  //   {
  // name: "Test LP Market",
  // address: "3PGNZtiG6xdG5qb66MimGm8Xh6UzKitkCzt"
  // },
//   {
//     name: "Main Market",
//     address: "3P4uA5etnZi4AmBabKinq2bMiWU8KcnHZdH"
//   },
//   {
//     name: "ROME Market",
//     address: "3P8Df2b7ywHtLBHBe8PBVQYd3A5MdEEJAou"
//   },
//   // {
//   //   name: "Puzzle Pool",
//   //   address: "3P6dkRGSqgsNpQFbSYn9m8n4Dd8KRaj5TUU"
//   // },
//   {
//     name: "Waves DeFi Pool",
//     address: "3P4DK5VzDwL3vfc5ahUEhtoe5ByZNyacJ3X"
//   },
//   {
//     name: "Low Cap",
//     address: "3PHpuQUPVUoR3AYzFeJzeWJfYLsLTmWssVH"
//   },
//   {
//     name: "Axly Pool",
//     address: "3PAd9Aqg3cQSzu26MFYWQYcjSXPVCJtuf5V"
//   },
//   // {
//   //   name: "Global Pool",
//   //   address: "3PJPpKuM7NTC9QaDqAPY4V8wtRWWmLBSSsY"
//   // }
// ];

const POOLS_LIST: Record<string, Array<IPool>> = {
  PROD: POOLS_PROD,
  DEV: POOLS_DEV
};

export const POOLS = POOLS_LIST[process.env.REACT_APP_NODE_ENV ?? "DEV"];

export const TOKENS_LIST: Array<IToken> = Object.values(tokens).map((t) => ({
  ...t,
  logo: tokenLogos[t.symbol]
}));
export const TOKENS_BY_SYMBOL: Record<string, IToken> = TOKENS_LIST.reduce(
  (acc, t) => ({ ...acc, [t.symbol]: t }),
  {}
);
export const TOKENS_BY_ASSET_ID: Record<string, IToken> = TOKENS_LIST.reduce(
  (acc, t) => ({ ...acc, [t.assetId]: t }),
  {}
);

export const NODE_URL = "https://nodes-puzzle.wavesnodes.com";
export const EXPLORER_URL = "https://new.wavesexplorer.com";

export interface IPool {
  name: string;
  address: string;
  description?: string;
}

export interface IToken {
  assetId: string;
  name: string;
  symbol: string;
  decimals: number;
  startPrice?: number;
  description?: string;
  logo: string;
  category?: string[];
  underlying?: string[];
}

export enum ASSETS_TYPE {
  SUPPLY_BLOCK,
  HOME,
  BORROW_BLOCK
}


export const INDEX_TOKENS: IIndexToken[] = [
  {
    poolId: "3P3EnYA57kMZ8kXVVThi1ZZApZeXUQHqtEe",
    assetId: "XjdJKWtPYCz585QB7LnxDP76UGRukazedDubUx9DHQH",
    name: "PZ WEB",
    symbol: "PZ WEB",
    decimals: 8,
    startPrice: 15,
    tokens: TOKENS_LIST.filter((t) => ["WAVES", "WBTC", "ETH"].includes(t.symbol)),
  },
  // {
  //   assetId: "rZMQ6g31Lr7sPAaAoYc4U2PHCVauTuKUSzUbJtUyPZN",
  //   name: "WX-USDCUSDT-LP",
  //   symbol: "WX-USDCUSDT-LP",
  //   decimals: 8,
  //   startPrice: 1,
  //   tokens: TOKENS_LIST.filter((t) => ["USDT", "USDC"].includes(t.symbol)),
  // },
  {
    poolId: "3PCpjLGo4wDuv23kjmGF6mHs86Bf3soYvAq",
    assetId: "6bZbRmou7M7wXBunMXQnZ4Rm66HxZF3KfMEiFwk3wmnA",
    name: "PZ 5pool",
    symbol: "PZ 5pool",
    decimals: 8,
    startPrice: 2.14,
    tokens: TOKENS_LIST.filter((t) => ["PLUTO", "XTN", "WX", "WAVES", "ETH"].includes(t.symbol)),
  },
  {
    poolId: "3P4U6fo7BZHDymQK82G5pveGPH7CA6PbKit",
    assetId: "EA7siGMSTxz6EtdpkCiVWQHupFT5N7UbvQrW9kvxCE42",
    name: "PZ Units",
    symbol: "PZ-Units",
    decimals: 8,
    startPrice: 10.85,
    tokens: TOKENS_LIST.filter((t) => ["PLUTO", "ETH", "L2MP", "WAVES"].includes(t.symbol)),
  },
]

export const INDEX_TOKENS_BY_ASSET_ID: Record<string, IToken[]> = INDEX_TOKENS.reduce(
  (acc, t) => ({ ...acc, [t.assetId]: t.tokens }),
  {}
)
export interface IIndexToken extends Partial<IToken> {
  assetId: string;
  name: string;
  symbol: string;
  decimals: number;
  poolId: string;
  tokens: IToken[];
}